const AppConfig = {
  navbar: {
    coverContent: true,
    background: "rgba(0,0,0,0)",
    altBackground: "#71C5D1",
    textColor: "white",
    textShadow: "2px 2px #ccc",
    height: 100
  },

  footer: {}
};

export default AppConfig;
