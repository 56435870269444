import React, { Component } from "react";
import styled from "styled-components";
import LangSwitcher from "../LangSwitcher";
import Link from "../Link";

const FooterFactory = config => {
  class Footer extends Component {
    render() {
      let { extraStyle = "" } = this.props;

      return (
        <Wrapper extraStyle={extraStyle}>
          <Content main>
            <div className="company-name">讀樂樂-親子共讀繪本 股份有限公司</div>
          </Content>

          <Content>
            <Link className="privacy-policy" to="/privacy">
              隱私權政策
            </Link>
            <div className="copyright">
              {"Revtel Tech 製作\nhttps://www.revtel.tech"}
            </div>
          </Content>
        </Wrapper>
      );
    }
  }

  let Wrapper = styled.div`
    background-color: #7e7e7f;
    width: 100vw;
    padding: 30px;
    color: #505050;
    display: flex;
    align-items: center;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
    }
    ${props => props.extraStyle};
  `;

  let Content = styled.div`
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: ${props => (props.main ? 1 : 0)};
    min-width: 200px;

    & > .company-name {
      color: white;
      font-size: 20px;
    }

    & > .copyright {
      white-space: pre-wrap;
      color: white;
      font-size: 20px;
    }

    & > .title {
      font-size: 15px;
      margin-bottom: 10px;
    }

    & > .menu {
      flex-grow: 1;
    }

    & > .privacy-policy {
      margin-bottom: 10px;
      text-decoration: underline;
      cursor: pointer;
      font-size: 18px;
      color: white;
    }

    @media screen and (max-width: 500px) {
      margin-top: 20px;
      width: 100%;
    }
  `;
  return Footer;
};

export default FooterFactory;
