import React from "react";
import styled from "styled-components";
import { animateScroll as aniScroll } from "react-scroll";
import Link from "../Link";
import * as Widget from "../Widget";
import * as Icon from "../Icon";

export default function NavbarFactory({
  coverContent = true,
  background = "rgba(0,0,0,0)",
  altBackground = "#0079ff",
  textColor = "white",
  textShadow = "2px 2px #ccc",
  height = 60
} = {}) {
  const ScreenSizeBoundry = 800;
  const navItems = [
    { children: "App特色", id: "app_feature" },
    { children: "徵稿辦法", id: "contribution_flow" },
    { children: "立即報名", id: "contribution" }
  ];

  class Navbar extends React.Component {
    state = {
      openMenu: false
    };

    render() {
      let { passBreakpoint } = this.props;

      return (
        <Style.Wrapper passBreakpoint={passBreakpoint}>
          <Style.Content>
            <img className="logo" src="/images/babyvoice-logo.png" />
            <Link to="/" className="site-title">
              讀樂樂-親子共讀繪本
            </Link>

            {this._smallScreenNavBar()}
            {this._largeScreenNavBar()}
          </Style.Content>
        </Style.Wrapper>
      );
    }

    _scrollSection(id) {
      const element = document.getElementById(id);
      const targetHeight = element.offsetTop;

      this.setState({ OpenMenu: false });
      aniScroll.scrollTo(targetHeight - 100, { duration: 400 });
    }

    _smallScreenNavBar() {
      const toggleMenuState = () =>
        this.setState({ openMenu: !this.state.openMenu });
      return (
        <Style.SmallScreenWrapper Breakpoint={ScreenSizeBoundry}>
          <Widget.IconButton onClick={toggleMenuState}>
            <Icon.Apps color="white" />
          </Widget.IconButton>

          <Style.SmallScreenMenu
            open={this.state.openMenu}
            onClick={toggleMenuState}
          >
            <div className="container">
              {[
                { children: <Icon.Home color="white" />, url: "/" },
                ...navItems
              ].map(({ children, id }, idx) => (
                <div
                  key={idx}
                  className="nav-item"
                  onClick={() => this._scrollSection(id)}
                >
                  {children}
                </div>
              ))}
            </div>
          </Style.SmallScreenMenu>
        </Style.SmallScreenWrapper>
      );
    }

    _largeScreenNavBar() {
      return (
        <Style.LargeScreenWrapper Breakpoint={ScreenSizeBoundry}>
          {navItems.map(({ children, id }, idx) => (
            <div
              key={idx}
              className="nav-item"
              onClick={() => this._scrollSection(id)}
            >
              {children}
            </div>
          ))}
        </Style.LargeScreenWrapper>
      );
    }
  }

  const Style = {
    Wrapper: styled.div`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      padding: 0px 20px;
      z-index: 10;
      background: ${props =>
        props.passBreakpoint ? altBackground : background};
      transition: background 200ms ease-in-out;
    `,

    Content: styled.div`
      margin: 0 auto;
      max-width: 1280px;
      height: ${height}px;
      display: flex;
      align-items: center;

      & > .logo {
        margin-right: 26px;
        width: 100px;
        height: 100px;
      }

      & > .site-title {
        color: ${textColor};
        text-decoration: none;
        font-size: 30px;
      }
    `,

    LargeScreenWrapper: styled.div`
      @media screen and (max-width: ${props => props.Breakpoint || 768}px) {
        display: none;
      }

      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      & > .nav-item {
        margin-right: 15px;
        font-size: 20px;
        color: ${textColor};
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      & > .nav-item :last-child {
        margin-right: 0px;
      }
    `,

    SmallScreenWrapper: styled.div`
      @media screen and (min-width: ${props => props.Breakpoint || 768}px) {
        display: none;
      }

      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    `,

    SmallScreenMenu: styled.div`
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: ${props => (props.open ? "0" : "-100vw")};
      background-color: ${props =>
        props.open ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)"};
      transition: background-color 250ms linear,
        left 0ms linear ${props => (props.open ? "0ms" : "250ms")};
      z-index: 5;

      & > .container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding-top: 30px;

        & > .nav-item {
          color: ${textColor};
          height: 50px;
          text-decoration: none;
        }
      }
    `
  };

  return Navbar;
}
