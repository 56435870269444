import Apps from "material-ui/svg-icons/navigation/apps";
import Star from "material-ui/svg-icons/toggle/star";
import StarBorder from "material-ui/svg-icons/toggle/star-border";
import Stars from "material-ui/svg-icons/action/stars";
import People from "material-ui/svg-icons/social/people";
import Person from "material-ui/svg-icons/social/person";
import Close from "material-ui/svg-icons/navigation/close";
import PlayOutline from "material-ui/svg-icons/av/play-circle-outline";
import Favorite from "material-ui/svg-icons/action/favorite-border";
import Share from "material-ui/svg-icons/social/share";
import ArrowUp from "material-ui/svg-icons/navigation/arrow-drop-up";
import ArrowDown from "material-ui/svg-icons/navigation/arrow-drop-down";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import ArrowForward from "material-ui/svg-icons/navigation/arrow-forward";
import KeyboardArrowUp from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import KeyboardArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import KeyboardArrowLeft from "material-ui/svg-icons/hardware/keyboard-arrow-left";
import KeyboardArrowRight from "material-ui/svg-icons/hardware/keyboard-arrow-right";
import RadioButtonUnchecked from "material-ui/svg-icons/toggle/radio-button-unchecked";
import RadioButtonChecked from "material-ui/svg-icons/toggle/radio-button-checked";
import Check from "material-ui/svg-icons/navigation/check";
import Edit from "material-ui/svg-icons/image/edit";
import CheckBox from "material-ui/svg-icons/action/check-circle";
import AddCircle from "material-ui/svg-icons/content/add-circle";
import AddCircleOutline from "material-ui/svg-icons/content/add-circle-outline";
import RemoveCircle from "material-ui/svg-icons/content/remove-circle";
import RemoveCircleOutline from "material-ui/svg-icons/content/remove-circle-outline";
import Menu from "material-ui/svg-icons/navigation/menu";
import PlayCircleOutline from "material-ui/svg-icons/av/play-circle-outline";
import Search from "material-ui/svg-icons/action/search";
import AccountCircle from "material-ui/svg-icons/action/account-circle";
import Email from "material-ui/svg-icons/communication/email";
import Lock from "material-ui/svg-icons/action/lock";
import Bookmark from "material-ui/svg-icons/action/bookmark";
import UnfoldMore from "material-ui/svg-icons/navigation/unfold-more";
import Delete from "material-ui/svg-icons/action/delete";
import Reply from "material-ui/svg-icons/content/reply";
import Language from "material-ui/svg-icons/action/language";
import WbSunny from "material-ui/svg-icons/image/wb-sunny";
import CloudDone from "material-ui/svg-icons/file/cloud-done";
import SupervisorAccount from "material-ui/svg-icons/action/supervisor-account";
import Loyalty from "material-ui/svg-icons/action/loyalty";
import Cart from "material-ui/svg-icons/action/shopping-cart";
import ExpandMore from "material-ui/svg-icons/navigation/expand-more";
import AutoRenew from "material-ui/svg-icons/action/autorenew";
import Add from "material-ui/svg-icons/content/add";
import Home from "material-ui/svg-icons/action/home";

export {
  Apps,
  Star,
  StarBorder,
  Stars,
  People,
  Person,
  Close,
  PlayOutline,
  Favorite,
  Share,
  ArrowDown,
  ArrowUp,
  KeyboardArrowUp,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Check,
  Edit,
  CheckBox,
  AddCircle,
  AddCircleOutline,
  RemoveCircle,
  RemoveCircleOutline,
  Menu,
  PlayCircleOutline,
  Search,
  AccountCircle,
  Email,
  Lock,
  Bookmark,
  ArrowBack,
  ArrowForward,
  UnfoldMore,
  Delete,
  Reply,
  Language,
  WbSunny,
  CloudDone,
  SupervisorAccount,
  Loyalty,
  Cart,
  ExpandMore,
  AutoRenew,
  Add,
  Home
};
